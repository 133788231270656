export const MediaMaxWidth = {
  XS: '(max-width: 374px)',
  SM: '(max-width: 767px)',
} as const

export type MediaMaxWidth = (typeof MediaMaxWidth)[keyof typeof MediaMaxWidth]

export const Loading = {
  Eager: 'eager',
  Lazy: 'lazy',
} as const

export type Loading = (typeof Loading)[keyof typeof Loading]
